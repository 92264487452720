<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
    <div class="text-center">
      <h4 class="mt-5">
        Update Scheduled Diploma
        <feather-icon icon="Edit2Icon" />
      </h4>
    </div>
    <validation-observer ref="addCourseFormvalidate">
      <b-form
        class="mt-2"
        style="width: 100%"
        @submit.prevent="save"
      >
        <b-row>
          <b-col md="4">
            <b-form-group
              label="Category"
              label-for="blog-edit-title"
              class="mb-2"
            >

              {{ addCourseForm.category }}

            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Diploma "
              label-for="blog-edit-title"
              class="mb-2"
            >
              {{ addCourseForm.long_course_name }}
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="City"
              label-for="blog-edit-category"
              class="mb-2"
            >
              {{ addCourseForm.city }}
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group
              label="Permanent price"
              label-for="blog-edit-slug"
              class="mb-2"
            >
              {{ addCourseForm.price }}
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Course Type"
              label-for="blog-edit-category"
              class="mb-2"
            >
              <span v-if="addCourseForm.online==1">
                Online

              </span>
              <span v-else> Classic</span>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            md="4"
            xl="4"
          >
            <b-form-group
              label="Select Date"
              label-for="blog-edit-course"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Date"
                rules=""
              >

                <flat-pickr
                  v-model="addCourseForm.date"
                  class="form-control"
                />
              </validation-provider>

            </b-form-group>
          </b-col>
          <b-col
            md="4"
            xl="4"
          >
            <b-form-group
              label="Select Instructor"
              label-for="blog-edit-course"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Courses"
                rules=""
              >
                <v-select
                  id="blog-edit-category"
                  v-model="addCourseForm.instructor_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="instructorList"
                  :reduce="(val) => val.id"
                />
              </validation-provider>

            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              label="New Price"
              label-for="blog-edit-slug"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="price"
                rules="required"
              >
                <b-form-input
                  id="blog-edit-slug"
                  v-model="addCourseForm.new_price"
                  type="number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            class="mt-50"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              Save Changes
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              @click="$router.go(-1)"
            >
              Back
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,

  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,

  BButton,

} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'

import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref, reactive } from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import Vue from 'vue'
import store from '@/store'

export default {
  setup() {
    const refInputEl = ref(null)
    const refInputE2 = ref(null)
    const refPreviewEl = ref(null)
    const refPreviewE2 = ref(null)
    const refInputE3 = ref(null)

    const refPreviewE3 = ref(null)
    const Tabimage = ref('media/svg/files/blank-image.svg')
    const { route } = useRouter()
    const { id } = route.value.params
    const validLink = ref(true)
    const CourseForm = ref({})
    const addCourseForm = reactive(CourseForm)
    const checkLink = link => {
      const formData = new FormData()

      formData.append('slug', link)
      store.dispatch('courses/checkLink', formData)
        .then(response => {
          validLink.value = response.data.valid
        })
    }

    const removeSpace = target => {
      addCourseForm.value.link_id = target.toLowerCase() // LowerCase
        .replace(/\s+/g, '-') // space to -
        .replace(/&/g, '') // & to and
        .replace(/--/g, '-')
        .replace(/[.,]/g, '-')
        .replace(/[_]/g, '')
        .replace(/[!''"":=]/g, '')
        .replace(/[!?%$^~*#()+]/g, '')
    }
    //   const productId = productSlug.substring(id.lastIndexOf('-') + 1)

    store.dispatch('longschedule/GetScheduleCourse', { id }).then(response => {
      //   console.log("response?.data",response?.data)
      CourseForm.value = response?.data.data
    })
    const instructorList = ref([])
    store.dispatch('longschedule/getAllInstructor').then(response => {
      instructorList.value = response.data.data
      //   console.log("instructorList?.data",response?.data)
    })
    const { inputImageRendererTab } = useInputImageRenderer(
      refInputE2,
      base64 => {
        refPreviewE2.value.src = base64
      },
    )

    //   console.log("CourseForm", CourseForm);
    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      base64 => {
        refPreviewEl.value.src = base64
      },
    )
    const addCourseFormvalidate = ref()

    const save = () => {
      const formData = new FormData()
      if (validLink.value == true) {
        formData.append('date', addCourseForm.value.date)
        formData.append('new_price', addCourseForm.value.new_price)
        formData.append('instructor_id', addCourseForm.value.instructor_id)

        formData.append('_method', 'put')
        //  addCourseFormvalidate.value.validate().then(success => {
        //         if (success) {
        store
          .dispatch('longschedule/Updateschedule', { id, formData })
          .then(response => {
            Vue.swal({
              icon: 'success',
              title: 'Updated!',

              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          })
      } else {
        Vue.swal({
          title: '',
          text: 'Please Enter Valid Link',
          icon: 'error',
          confirmButtonText: 'ok',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      }
      // }})
    }
    const { inputImageRendererTab3 } = useInputImageRenderer(
      refInputE3,
      base64 => {
        refPreviewE3.value.src = base64
      },
    )
    return {
      refInputEl,
      refPreviewEl,
      refInputE2,
      refPreviewE2,
      Tabimage,
      addCourseForm,
      inputImageRenderer,
      addCourseFormvalidate,
      validLink,
      checkLink,
      id,
      CourseForm,
      refInputE3,
      refPreviewE3,
      inputImageRendererTab3,
      inputImageRendererTab,
      removeSpace,

      save,
      required,
      email,
      instructorList,
    }
  },

  components: {
    BCard,

    ValidationProvider,
    ValidationObserver,

    BForm,

    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,

    flatPickr,
    vSelect,

  },

  directives: {
    Ripple,
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
